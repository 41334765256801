<template>
  <div class="profile-steps" v-if="showSteps">
    <div class="steps-title">Set-up your profile!</div>
    <div class="swiper-box">
      <div class="steps-swiper-container">
        <div class="swiper-wrapper">
          <div :class="`swiper-slide is-relative step${index + 1}`" v-for="(step, index) in steps" :key="step.stepName">
            <div class="step-index has-text-blue has-text-weight-semibold">STEP {{index + 1}}</div>
            <div class="step-title has-text-weight-semibold">{{step.title}}</div>
            <div :class="`step-status is-flex is-align-items-center ${step.status}`">
              <i :class="`status-icon ${step.status}`"></i>
              <span :class="`is-uppercase has-text-weight-semibold ${step.status}`">{{statusText(step.status)}}</span>
            </div>
            <button class="button is-loading" v-if="step.stepName === 'confidentiality' && (isFetchingAddySignUrl || showHelloSign)"></button>
          </div>
        </div>
      </div>
    </div>
    <IdVerificationModal
      :showModal="showVerificationModal"
      :closeModal="() => { showVerificationModal = false }"
      :governmentIdStatus="governmentIdStatus"
      :investorOnVeriffWatchlist="investorOnVeriffWatchlist"
      :useVeriff="isVeriffEnabled"
    >
    </IdVerificationModal>
    <div class="addySign-container" v-if="showAddySignModal">
      <button class="modal-close is-large" @click="toggleAddySignModal(false)"></button>
      <iframe :src="addySignUrl" title="Confidentiality Agreement" id="addySign-iframe"></iframe>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import IdVerificationModal from '@components/modal/id-verification-modal.vue'
import { getProfileSteps } from '@api/dashboard'
import { getProfileDetails } from '@api/profile'
import signConfidentialityAgreement from '@utils/mixins/sign-confidentiality-agreement.js'
import walletRoutes from '@utils/mixins/wallet-routes.js'
import dashboardCommonMethods from '@utils/mixins/dashboard-common-methods'
import { mapState } from 'vuex'

export default {
  mixins: [signConfidentialityAgreement, walletRoutes, dashboardCommonMethods],
  data() {
    return {
      profileSteps: [],
      showSteps: false,
      governmentIdStatus: '',
      hasAcceptedConfidentiality: false,
      confidentialityAgreementUrl: '',
      investorOnVeriffWatchlist: false,
    }
  },
  components: {
    IdVerificationModal,
  },
  props: {
    email: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
  },
  created() {
    this.getAccountStatus()
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      getProfileSteps().then((data) => {
        if (!data.success) return
        data.data.some((step) => {
          if (step.status !== 'complete') {
            this.showSteps = true
            return true
          }
        })
        this.profileSteps = data.data
        /* eslint-disable no-new */
        this.$nextTick(() => {
          const mySwiper = new Swiper('.steps-swiper-container', {
            initialSlide: 0,
            slidesPerView: 1.3,
            spaceBetween: 12,
            loop: true,
            slideToClickedSlide: true,
          })
          mySwiper.on('click', () => {
            setTimeout(() => {
              this.toStep(this.steps[mySwiper.realIndex], 'mobile')
            }, 200)
          })
        })
      })
    },
    statusText(status) {
      return status === 'out-of-area' ? 'not available in your region yet' : status
    },
    getAccountStatus() {
      getProfileDetails().then((result) => {
        if (!result.success) return
        const { governmentIdStatus, confidentialityAgreementUrl, hasAcceptedConfidentiality, investorOnVeriffWatchlist } = result.data
        this.governmentIdStatus = governmentIdStatus || 'TO-DO'
        this.confidentialityAgreementUrl = confidentialityAgreementUrl
        this.hasAcceptedConfidentiality = hasAcceptedConfidentiality
        this.investorOnVeriffWatchlist = investorOnVeriffWatchlist
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.profile-steps {
  padding-bottom: 25px;
  .steps-title {
    font-size: 16px;
    padding-bottom: 8px;
    padding-left: 25px;
  }
  .swiper-box {
    width: 100vw;
    overflow: hidden;
    padding-left: 25px;
    .steps-swiper-container {
      height: 126px;
      overflow: unset;
      .swiper-wrapper {
        .swiper-slide {
          overflow: hidden;
          border-radius: 12px;
          &.step1 {
            background: #F0EEF8 url('../../assets/images/dashboard/step1.png') center/cover no-repeat;
          }
          &.step2 {
            padding-right: 50px;
            background: #F0EEF8 url('../../assets/images/dashboard/step2.png') center/cover no-repeat;
          }
          &.step3 {
            background: #F0EEF8 url('../../assets/images/dashboard/step3.png') center/cover no-repeat;
          }
          &.step4 {
            background: #F0EEF8 url('../../assets/images/dashboard/step4.png') center/cover no-repeat;
          }
          &.step5 {
            background: #F0EEF8 url('../../assets/images/dashboard/step5.png') center/cover no-repeat;
          }
          &.step6 {
            padding-right: 20px;
            background: #F0EEF8 url('../../assets/images/dashboard/step6.png') center/cover no-repeat;
          }
          &.step7 {
            background: #F0EEF8 url('../../assets/images/dashboard/step7.png') center/cover no-repeat;
          }
          .step-index {
            font-size: 14px;
            padding: 12px 0 8px 24px;
          }
          .step-title {
            font-size: 24px;
            padding: 0 8px 12px 24px;
          }
          .step-status {
            position: absolute;
            bottom: 13px;
            left: 24px;
            &.failed {
              bottom: 11px;
            }
            .status-icon {
              display: inline-block;
              width: 12px;
              height: 12px;
              &.TO-DO {
                background: url('../../assets/icons/dashboard-dash.svg') center/contain no-repeat;
              }
              &.complete {
                background: url('../../assets/icons/dashboard-check.svg') center/contain no-repeat;
              }
              &.pending {
                background: url('../../assets/icons/dashboard-circle.svg') center/contain no-repeat;
              }
              &.processing {
                background: url('../../assets/icons/dashboard-circle.svg') center/contain no-repeat;
              }
              &.failed {
                width: 18px;
                height: 18px;
                background: url('../../assets/icons/exclamation.svg') center/contain no-repeat;
              }
              &.out-of-area {
                width: 18px;
                height: 18px;
                background: url('../../assets/icons/exclamation-aqua.svg') center/contain no-repeat;
              }
            }
            span {
              color: #3CDBC0;
              padding-left: 8px;
              &.failed {
                color: #C91717;
              }
            }
          }
          .button.is-loading {
            border: none;
            background-color: #F0EEF8;
            position: absolute;
            bottom: 2px;
            right: 10px;
          }
        }
      }
    }
  }
  .addySign-container {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .modal-close {
      top: 0.5rem;
      left: 6px;
      &::before,
      &::after {
        background-color: #4A26AA;
      }
    }
    #addySign-iframe {
      width: 100%;
      height: 100%;
    }
  }
  .verification-modal-text {
    font-size: 16px;
    line-height: 19px;
  }
}
</style>
