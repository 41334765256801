<template>
  <div class="dashboard-wrap is-relative">
    <div class="teaser-wrap" v-show="showRecentInvestorsBanner">
      <Teaser
        :investmentsData="investmentsBannerData"
        @toggleTeaser="toggleTeaser"
      ></Teaser>
    </div>
    <div :class="`menu-and-logo is-flex is-justify-content-center is-align-items-center ${addySignModalIsActive ? 'lower-z-index' : ''}`">
      <img class="menu-icons" src="../../assets/icons/menu.svg" alt="menu" @click="showMenu">
      <img class="logo" src="../../assets/images/common/logo.png" alt="logo">
      <div
        :class="`referral-icon has-background-white has-text-weight-bold is-flex is-justify-content-center is-align-items-center ${seenReferralIcon ? '' : 'bouncing'}`"
        @click="toReferralDashboard"
      >
        <img src="../../assets/icons/gift.svg" alt="gift">
        <div
          class="notice-icon is-flex is-justify-content-center is-align-items-center has-text-white"
          @click.stop="()=>{return false}"
          v-if="!seenReferralIcon || hasNewReferrals"
        >!</div>
      </div>
    </div>
    <div class="top-background">
      <div class="investor-info">
        <div class="investor-info-box is-inline-flex is-align-items-center" @click="toMemberProfile">
          <img src="@assets/images/dashboard/investor.png" alt="investor-icon" class="investor-icon">
          <div>
            <div class="welcome has-text-weight-semibold">Welcome {{showFirstTimeMessage ? 'aboard' : 'back'}}, {{firstName}}!</div>
            <div class="created" v-if="membershipType !== 'Unpaid'">{{membershipType}} since {{membershipChargedAt}}</div>
          </div>
        </div>
      </div>
    </div>
    <div :class="`main-content ${addySignModalIsActive ? 'no-scrolling' : ''}`" @scroll="monitorPageScrolling">
      <div class="placeholder">
        <div
          class="mask-over-investor-block"
          :style="`width: ${widthOfPlaceholder}vw;`"
          @click="toMemberProfile"
        ></div>
      </div>
      <div class="recent-investors-banner-icon is-justify-content-center is-align-items-center" @click="showInvestorsBanner" v-show="!showRecentInvestorsBanner && showRecentInvestorsBannerIcon">
        <img src="@assets/icons/polygon.svg" alt="polygon" class="polygon">
      </div>
      <div :class="`investment-info ${scrollingFlag ? '' : 'fixed'}`">
        <div class="investment-box-wrap is-relative">
          <div class="shadow-box"></div>
          <div class="investment-box has-text-centered has-background-white">
            <div class="switch-portfolio is-flex is-justify-content-center is-align-items-center is-clickable" @click="switchPortfolio">
              <span class="icon">
                <i class="fas fa-exchange-alt has-text-blue"></i>
              </span>
            </div>
            <div class="investment-title has-text-weight-semibold">Investment Portfolio {{countryFlag}}</div>
            <div v-if="isPortfolioLoading">
              <b-skeleton width="40%" height="53px" position="is-centered"></b-skeleton>
            </div>
            <div v-else class="investment-total">
              <span class="has-text-blue has-text-weight-semibold sign">$</span>
              <span class="has-text-blue is-relative num" @click="toPortfolio">{{totalInvestment | formatter}}
                <span class="currency has-text-blue has-text-weight-bold" v-if="dataLoaded">{{countryCurrency}}</span>
              </span>
            </div>
            <div class="investment-link">
              <a @click="toInvestmentLink" class="has-text-primary has-text-weight-semibold is-inline-flex is-align-items-center">{{actionOnPortfolioPanel}}
                <span class="has-text-weight-bold has-text-primary">+</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div :class="`dashboard-content has-background-white ${scrollingFlag ? '' : 'has-top-fixed'}`">
        <div :class="`mask ${scrollingFlag ? '' : 'fixed'}`">
          <div class="purple-box"></div>
        </div>
        <div class="dashboard-panels">
          <Steps
            :email="email"
            @updateAddySignModalStatus="updateAddySignModalStatus"
          ></Steps>
          <div class="card-title">Your addy wallet</div>
          <WalletCard :data="walletBalanceCard" @switchWallet="switchWallet">
            <div class="add-fund is-flex is-justify-content-center is-align-items-center" @click="toAddFund">
              <img src="@assets/icons/plus.svg" alt="add">
            </div>
          </WalletCard>
          <Blogs
            :recentBlogs="blogsData"
            @updateViewedBlog="updateViewedBlog"
            ref="blogs"
          ></Blogs>
          <Feedback></Feedback>
        </div>
      </div>
    </div>

    <WelcomeOnboardModal
      :showFirstTimeMessage="showFirstTimeMessage"
      :bankAccountConnected="bankAccountConnected"
      @toggleFirstTimeMessage="toggleFirstTimeMessage"
      @toAddFund="toAddFund"
      @toLinkBankPage="toLinkBankPage"
    ></WelcomeOnboardModal>

    <!-- referral modal is deprecated, need a code cleanup here -->
    <StandardModal
      :showModal="showReferralModal"
      :closeModal="closeReferralModalAction"
      :buttonTappedAction="referralButtonTappedAction"
      title="Refer your friends & earn $25! 🤝"
      readMoreUrl="https://www.addyinvest.com/2021/06/29/referral-program/"
      buttonTitle="Invite Now"
      >
      <div class="modal-text has-text-centered">
        <div class="mb-4">For a limited time only! You and your friend both earn $25 for every new friend who joins addy as a member.
          Share your unique link with your friends and family and earn🔥 rewards like a PS5 or a Macbook Pro for hitting certain milestones.
        </div>
        <div class="mb-4">Access your referral page at the top right corner of your dashboard 🎁</div>
        <div>#SharingIsCaring.</div>
      </div>
    </StandardModal>

    <OwnersDayModal
      :distributionData="distributionData"
      @updateDistributionData="updateDistributionData"
    ></OwnersDayModal>

    <MembershipRenewalModal
      :showMembershipExpiryModal="showMembershipExpiryModal"
      :inAvailableRegion="inAvailableRegion"
      @toggleMembershipExpiryModal="toggleMembershipExpiryModal"
    ></MembershipRenewalModal>
  </div>
</template>

<script>
import { CountryMap, CountryFlagMap, CountryCurrencyMap } from '@utils/data/countries'
import setViewportHeight from '@utils/mixins/set-viewport-height.js'
import walletRoutes from '@utils/mixins/wallet-routes.js'
import walletCurrencySwitch from '@utils/mixins/wallet-currency-switch-mixin'
import Teaser from '@components/teaser/investment-teaser.vue'
import Steps from './ProfileSteps.vue'
import WalletCard from './WalletCard.vue'
import Blogs from './Blogs.vue'
import Feedback from './Feedback.vue'
import StandardModal from '@components/modal/standard-modal.vue'
import WelcomeOnboardModal from '@components/dashboard-modals/welcome-onboard.vue'
import MembershipRenewalModal from '@components/dashboard-modals/membership-renewal.vue'
import OwnersDayModal from '@components/dashboard-modals/owners-day.vue'
import axios from '@lib/axios/middleware'
import { getInvestorData } from '@api/signup'
import { updateInvestorFlags, getInvestorFlags, getFeatureToggleList, getMembershipStatus } from '@api/common'
import { getBalance, getPropertyBaseUrl, recentInvestmentsBanner, fetchBlogData, getReferralFlags, getActivePropFlag } from '@api/dashboard'
import { getBankAccountStatus } from '@api/wallet'
import { DateTime } from 'luxon'
import { formatter } from '@utils/common-methods/numberFormatter'
import { investorInAvailableRegion } from '@utils/common-methods/common'
import { mapState } from 'vuex'

export default {
  mixins: [setViewportHeight, walletRoutes, walletCurrencySwitch],
  components: {
    Teaser,
    Steps,
    WalletCard,
    Blogs,
    Feedback,
    StandardModal,
    WelcomeOnboardModal,
    MembershipRenewalModal,
    OwnersDayModal,
  },
  data() {
    return {
      dataLoaded: false,
      hashid: '',
      firstName: '',
      membershipType: '',
      membershipDate: '',
      createdAt: '',
      portfolioCountry: 'CA',
      isPortfolioLoading: false,
      totalInvestment: 0,
      bankAccountConnected: false,
      email: '',
      walletCountry: 'CA',
      walletBalanceCard: {
        walletBalance: 0,
        addyHandle: '',
        firstName: '',
        lastName: '',
        processingDepositTotal: 0,
        walletCurrency: 'CAD',
      },
      scrollingFlag: true,
      showRecentInvestorsBanner: false,
      showRecentInvestorsBannerIcon: false,
      showFirstTimeMessage: false,
      firstTimeOnDashboard: false,
      interacDeprecationModalSeen: true,
      investmentsBannerData: {
        totalInvestorCount: 0,
        recentInvestments: [],
      },
      blogsData: [],
      showReferralModal: false,
      seenReferralIcon: true,
      hasNewReferrals: false,
      widthOfPlaceholder: 77,
      addySignModalIsActive: false,
      forSaleProperties: [],
      inAvailableRegion: true,
      distributionData: undefined,
      membershipExpired: false,
      membershipExpiryNotificationSeenAt: undefined,
      showMembershipExpiryModal: false,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel', 'identityIsSet']),
    membershipChargedAt() {
      if (!this.membershipDate) return ''
      return DateTime.fromISO(this.membershipDate).toFormat('MMMM dd, yyyy')
    },
    countryFlag() {
      return CountryFlagMap[this.portfolioCountry]
    },
    countryCurrency() {
      return CountryCurrencyMap[this.portfolioCountry]
    },
    actionOnPortfolioPanel() {
      if (!this.inAvailableRegion) return 'LINK BANK ACCOUNT'
      if (this.walletBalanceCard.walletBalance > 0 && this.forSaleProperties.length) return 'INVEST'
      return this.bankAccountConnected ? 'ADD FUNDS' : 'LINK BANK ACCOUNT'
    },
    titleForRegionNotAvailable() {
      if (!this.inAvailableRegion) return 'Add new bank'
      if (this.walletBalanceCard.walletBalance > 0 && this.forSaleProperties.length) return 'Invest in a property'
      return this.bankAccountConnected ? 'Fund wallet' : 'Add new bank'
    },
  },
  filters: {
    formatter(value) {
      return formatter(value)
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      axios.all([
        getInvestorData(),
        getBalance(),
        getBankAccountStatus(),
        getPropertyBaseUrl(),
        recentInvestmentsBanner(),
        fetchBlogData(),
        getReferralFlags(),
        getInvestorFlags(),
        getActivePropFlag(),
        getFeatureToggleList(),
        getMembershipStatus(),
      ]).then((data) => {
        this.hashid = data[0].hashid
        this.createdAt = data[0].createdAt
        this.firstName = data[0].firstName
        this.membershipType = data[0].membershipType
        this.membershipDate = data[0].membershipDate
        this.email = data[0].email
        this.walletBalanceCard.addyHandle = data[0].addyHandle
        this.walletBalanceCard.firstName = data[0].firstName
        this.walletBalanceCard.lastName = data[0].lastName
        this.showFirstTimeMessage = data[0].firstTimeOnMobileUi
        this.firstTimeOnDashboard = data[0].firstTimeOnMobileUi
        this.inAvailableRegion = investorInAvailableRegion(data[0].country, data[0].administrativeAreaLevel1)
        localStorage.setItem('investor', JSON.stringify(data[0]))
        this.$nextTick(() => {
          this.assignDynamicWidthToInvestorMask()
        })

        this.totalInvestment = data[1].data.investmentTotal
        this.walletBalanceCard.walletBalance = data[1].data.walletBalance
        this.walletBalanceCard.processingDepositTotal = data[1].data.processingDepositTotal
        if (data[2].success) this.bankAccountConnected = data[2].data.status
        if (data[3].success) this.$store.commit('setPropertyBaseUrlOnMenu', data[3].data.propertiesForSaleUrl)
        if (data[4].success) this.handleInvestmentTeaserData(data[4].data)
        if (data[5].success) {
          this.blogsData = data[5].data
          this.$nextTick(() => {
            this.$refs.blogs && this.$refs.blogs.initialize()
          })
        }
        if (data[6].success) {
          this.seenReferralIcon = data[6].data.referralGiftIconSeenAt
          this.hasNewReferrals = data[6].data.newReferrals
        }
        // get investor flags response
        if (data[7].success) this.handleInvestorFlags(data[7].data, data[10].data)
        if (data[8].success && data[8].data.property.hashid) this.forSaleProperties = [data[8].data.property.hashid]
        if (data[9].success) this.$store.commit('setFlipperToggles', data[9].data)
        if (data[10].success) this.getDataForMembershipStatus(data[10].data)

        this.$nextTick(() => {
          this.dataLoaded = true
        })
      })
    },
    handleInvestorFlags(data, membershipData) {
      this.interacDeprecationModalSeen = !!data.interacDeprecationModalSeenAt

      const { membershipPaid, membershipExpired } = membershipData
      if (data.distribution) {
        this.distributionData = {
          ...data.distribution,
          hasValidMembership: membershipPaid && !membershipExpired
        }
      }
      this.membershipExpiryNotificationSeenAt = data.membershipExpiryNotificationSeenAt
    },
    getDataForMembershipStatus({ membershipExpired }) {
      this.membershipExpired = membershipExpired
      this.showMembershipExpiryModal = membershipExpired && !this.membershipExpiryNotificationSeenAt
    },
    showMenu() {
      this.$store.commit('toggleMenu', true)
    },
    monitorPageScrolling() {
      const mainContent = document.querySelector('.main-content')
      if (mainContent.scrollTop >= 69) {
        if (this.scrollingFlag) this.scrollingFlag = false
      } else {
        if (!this.scrollingFlag) this.scrollingFlag = true
      }
    },
    showInvestorsBanner() {
      this.showRecentInvestorsBanner = true
    },
    toInvestmentLink() {
      if (!this.inAvailableRegion) {
        return this.$router.push({
          name: 'regionNotAvailable',
          query: { title: this.titleForRegionNotAvailable }
        })
      }
      if (this.walletBalanceCard.walletBalance > 0 && this.forSaleProperties.length) {
        this.$router.push('/allProperties')
      } else if (this.bankAccountConnected) {
        this.$router.push('/wallet/options')
      } else this.$router.push('/wallet/accounts/new')
    },
    toPortfolio() {
      this.$router.push('/portfolio')
    },
    toReferralDashboard() {
      updateInvestorFlags({
        referral_gift_icon_seen_at: DateTime.now().toFormat('yyyy-LL-dd HH:mm:ss'),
        referral_page_last_seen_at: DateTime.now().toFormat('yyyy-LL-dd HH:mm:ss'),
      })
      this.$router.push('/referralDashboard')
    },
    closeReferralModalAction() {
      updateInvestorFlags({
        referral_intro_modal_seen_at: DateTime.now().toFormat('yyyy-LL-dd HH:mm:ss')
      })
      this.showReferralModal = false
      if (this.firstTimeOnDashboard) {
        this.firstTimeOnDashboard = false
        setTimeout(() => {
          if (this.investmentsBannerData.recentInvestmentCount) this.showRecentInvestorsBanner = true
        }, 2000)
      }
    },
    referralButtonTappedAction() {
      this.closeReferralModalAction()
      this.$router.push('/referralDashboard')
    },
    assignDynamicWidthToInvestorMask() {
      const ele = document.querySelector('.investor-info-box')
      if (!ele) return
      const widthOfInvestorInfoBox = ele.clientWidth
      this.widthOfPlaceholder = widthOfInvestorInfoBox / window.screen.width * 100
    },
    toMemberProfile() {
      this.$router.push('/investorProfile')
    },
    toAddFund() {
      this.toAddFundsPage()
    },
    updateAddySignModalStatus(status) {
      this.addySignModalIsActive = status
    },
    updateViewedBlog(index) {
      this.blogsData[index].action = 'viewed'
    },
    toggleFirstTimeMessage(status) {
      this.showFirstTimeMessage = status
      // this.showReferralModal = true
    },
    toggleMembershipExpiryModal(status) {
      this.showMembershipExpiryModal = status
    },
    updateDistributionData(value) {
      this.distributionData = value
    },
    toggleTeaser(status) {
      this.showRecentInvestorsBanner = status
    },
    handleInvestmentTeaserData(data) {
      const copy = JSON.parse(JSON.stringify(data))
      // show only one purchase on mobile
      if (copy.recentInvestments.length) copy.recentInvestments = copy.recentInvestments.slice(0, 1)
      this.investmentsBannerData = copy

      if (copy.recentInvestmentCount) this.showRecentInvestorsBannerIcon = true
    },
    switchPortfolio() {
      if (this.isPortfolioLoading) return

      this.isPortfolioLoading = true
      this.portfolioCountry = this.portfolioCountry === 'CA' ? 'US' : 'CA'
      this.fetchInvestmentTotal()
    },
    fetchInvestmentTotal() {
      getBalance(CountryMap[this.portfolioCountry]).then((res) => {
        this.isPortfolioLoading = false
        if (!res.success) return
        this.totalInvestment = res.data.investmentTotal
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@keyframes bounce {
  7%   {top: 8px;}
  0%, 14%, 100%  {top: 15px;}
}

.dashboard-wrap {
  .teaser-wrap {
    position: fixed;
    right: 12px;
    top: 12px;
    z-index: 100;
  }
  .menu-and-logo {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 68px;
    background-color: #F0EEF8;
    &.lower-z-index {
      z-index: 2;
    }
    .menu-icons {
      position: absolute;
      top: 27px;
      left: 25px;
      width: 20px;
    }
    .logo {
      width: 63px;
    }
    .referral-icon {
      position: absolute;
      right: 25px;
      top: 15px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      &.bouncing {
        animation: bounce 4.6s ease-in-out infinite;
      }
      > img {
        width: 16px;
      }
      .notice-icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #3CDBC0;
        position: absolute;
        top: -7.5px;
        right: -15px;
        font-size: 16px;
      }
    }
  }
  .top-background {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #F0EEF8;
    padding-top: 68px;
    .investor-info {
      padding-top: 4px;
      padding-left: 25px;
      .investor-icon {
        width: 50px;
        margin-right: 8px;
      }
      .welcome {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  .main-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    z-index: 2;
    &.no-scrolling {
      overflow: hidden;
    }
    .placeholder {
      height: 176px;
      padding: 70px 0 0 25px;
      .mask-over-investor-block {
        height: 54px;
      }
    }
    .recent-investors-banner-icon {
      display: flex;
      position: fixed;
      z-index: 99;
      right: 0;
      top: 70px;
      width: 28px;
      height: 50px;
      background: #2D2926;
      border-radius: 12px 0px 0px 12px;
      opacity: 0.5;
    }
    .investment-info {
      position: absolute;
      z-index: 3;
      left: 0;
      top: 137px;
      padding: 0 25px;
      width: 100%;
      &.fixed {
        position: fixed;
        top: 68px;
      }
      .investment-box-wrap {
        border-radius: 12px;
        height: 138px;
        .shadow-box {
          color: red;
          position: absolute;
          bottom: 2px;
          left: 8px;
          width: 309px;
          height: 62px;
          box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
        }
        .investment-box {
          border-radius: 12px;
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          padding: 0;
          .switch-portfolio {
            width: 32px;
            height: 32px;
            position: absolute;
            top: 10px;
            right:  10px;
            border-radius: 6px;
            background: var(--addy-blue-light-2);
          }
          .investment-title {
            padding: 19px 0 8px;
            font-size: 16px;
            line-height: 19px;
          }
          .investment-total {
            .sign {
              font-size: 20px;
              padding-right: 6px;
              vertical-align: top;
              line-height: 35px;
            }
            .num {
              font-size: 48px;
              .currency {
                font-size: 16px;
                position: absolute;
                right: -32px;
                top: 8px;
              }
            }
          }
          .investment-link {
            padding-top: 10px;
            a {
              span {
                font-size: 20px;
                padding-left: 6px;
                margin-bottom: 2px;
              }
            }
          }
        }
      }
    }
    .dashboard-content {
      &.has-top-fixed .dashboard-panels {
        padding-top: 124px;
      }
      .mask {
        height: 44px;
        &.fixed {
          width: 100vw;
          height: 84px;
          position: fixed;
          z-index: 2;
          top: 68px;
        }
        .purple-box {
          height: 100%;
          background-color: #F0EEF8;
          border-bottom-left-radius: 24px;
          border-bottom-right-radius: 24px;
        }
      }
      .dashboard-panels {
        padding-top: 80px;
        padding-bottom: 100px;
        .card-title {
          font-size: 16px;
          padding-left: 25px;
          padding-bottom: 8px;
        }
        .add-fund {
          width: 32px;
          height: 32px;
          position: absolute;
          top: 8px;
          right: 50px;
          border-radius: 6px;
          background: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}
</style>
