<template>
  <div class="blogs-wrap">
    <div class="blogs-title">Read the blog</div>
    <div class="swiper-box">
      <div class="blogs-swiper-container">
        <div class="swiper-wrapper">
          <div
            v-for="(blog, index) in recentBlogs"
            :key="index"
            class="swiper-slide"
            :style="`background: url(${blog.blogImgSrc}) center/cover no-repeat;`">
            <div class="blog-mask is-relative">
              <div class="new-flag" v-if="blog.action === 'new'">
                <img src="@assets/icons/star.svg" alt="icon-top" class="icon-top">
                <span class="has-text-weight-semibold has-text-blue">NEW!</span>
                <img src="@assets/icons/star.svg" alt="icon-top" class="icon-bottom">
              </div>
              <div class="blog-title has-text-weight-semibold">{{blog.blogTitle}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import { updateBlogFlags } from '@api/dashboard'

export default {
  data() {
    return {
      blogSwiper: undefined,
    }
  },
  props: {
    recentBlogs: {
      default: () => {
        return []
      },
      required: true
    },
  },
  methods: {
    initialize() {
      /* eslint-disable no-new */
      this.blogSwiper = new Swiper('.blogs-swiper-container', {
        initialSlide: 0,
        slidesPerView: 1.3,
        spaceBetween: 12,
        loop: true,
        slideToClickedSlide: true,
      })
      this.blogSwiper.on('click', () => {
        setTimeout(() => {
          const swiperIndex = this.blogSwiper.realIndex
          const isNew = this.recentBlogs[swiperIndex].action === 'new'
          isNew && updateBlogFlags({
            blog_title: this.recentBlogs[swiperIndex].blogTitle,
            action: 'viewed',
          }).then(() => {
            this.removeTagFromDOM()
            this.$emit('updateViewedBlog', swiperIndex)
          })
          this.toBlog(this.recentBlogs[swiperIndex].blogLink)
        }, 200)
      })
    },
    removeTagFromDOM() {
      const elements = document.querySelectorAll('.swiper-slide-duplicate-active .new-flag')
      elements.forEach((element) => element.remove())
    },
    toBlog(url) {
      window.open(url)
    }
  },
}
</script>
<style lang="scss" scoped>
.blogs-wrap {
  .blogs-title {
    font-size: 16px;
    padding-bottom: 8px;
    padding-left: 25px;
  }
  .swiper-box {
    width: 100vw;
    overflow: hidden;
    padding-left: 25px;
    .blogs-swiper-container {
      height: 309px;
      overflow: unset;
      .swiper-wrapper {
        .swiper-slide {
          overflow: hidden;
          border-radius: 12px;
          .blog-mask {
            height: 101%;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 100%);
            .new-flag {
              position: absolute;
              left: 12px;
              top: 9px;
              width: 92px;
              height: 31px;
              line-height: 31px;
              background: rgba(255, 255, 255, 0.5);
              border-radius: 6px;
              text-align: center;
              .icon-top {
                position: absolute;
                top: -2px;
                left: 12px;
              }
              span {
                font-size: 14px;
              }
              .icon-bottom {
                position: absolute;
                right: 12px;
                bottom: -2px;
              }
            }
            .blog-title {
              position: absolute;
              left: 24px;
              bottom: 21px;
              font-size: 16px;
              line-height: 19px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              padding-right: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
